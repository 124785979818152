import i18n from "@/libs/i18n";

export default [
  // {
  //   header: 'PAYMENTS'
  // },
  // {
  //   title:'transfers',
  //   icon: 'ListIcon',
  //   route:'apps-transfers-list'
  // },
  // {
  //   title:'banks',
  //   icon: 'HomeIcon',
  //   route:'apps-banks-list'
  // },
  {
    header: 'modules',
  },
  {
    title: 'users',
    icon: 'UserIcon',
    route: 'apps-users-list',
    permission: 'users_list'
  },
  // {
  //   title: 'User',
  //   icon: 'UserIcon',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'apps-users-list',
  //     },
  //     {
  //       title: 'Hierarchy',
  //       route: 'apps-users-tree',
  //     },

  //   ],
  // },
  {
    title: 'roles',
    icon: 'UsersIcon',
    route: 'list-roles',
    permission: 'roles_list'
  }, 
  // {
  //   title: 'Modules',
  //   icon: 'LifeBuoyIcon',
  //   route: 'modules-list',
  // },
  {
    title: 'clients',
    icon: 'UserPlusIcon',
    route: 'apps-clients-list',
    permission: 'clients_list'
  },
  {
    title: 'whitelabels',
    icon: 'SettingsIcon',
    route: 'apps-whitelabels-list',
    permission: 'whitelabels_list'
  },
  {
    title: 'templates',
    icon: 'LayoutIcon',
    route: 'default-template-index',
    permission: ['show_template', 'list_menu_template', 'list_banners_template', 'list_headers_template', 'list_home_pages_template', 'list_footers_template', 'list_footers_template', 'list_sidebars_template', 'list_widgets_template', 'list_lobby_template'],
  },
  // {
  //   title: 'templates',
  //   icon: 'LayoutIcon',
  //   permission: ['show_template', 'list_menu_template', 'list_banners_template', 'list_headers_template', 'list_home_page_template', 'list_footers_template', 'list_footers_template', 'list_sidebars_template', 'list_widgets_template', 'list_lobby_template'],
  //   children: [
  //     {
  //       title: 'menus',
  //       icon: 'NavigationIcon',
  //       route: 'apps-menus-list',
  //       permission: 'list_menu_template'
  //     },
  //     {
  //       title: 'banner',
  //       icon: 'ImageIcon',
  //       route: 'apps-banners-list',
  //       permission: 'list_banners_template'
  //     },
  //     {
  //       title: 'headers',
  //       icon: 'ArrowUpIcon',
  //       route: 'apps-headers-list',
  //       permission: 'list_headers_template'
  //     },
  //     {
  //       title: 'home_pages',
  //       icon: 'HomeIcon',
  //       route: 'apps-homePages-list',
  //       permission: 'list_home_page_template'
  //     },
  //     {
  //       title: 'footers',
  //       icon: 'ArrowDownIcon',
  //       route: 'apps-footers-list',
  //       permission: 'list_footers_template'
  //     },
  //     {
  //       title: 'lobby',
  //       icon: 'ArrowDownIcon',
  //       route: 'lobby',
  //       permission: 'list_lobby_template'
  //     },
  //   ],
  // },
  {
    title: 'reports',
    icon: 'UserIcon',
    permission: 'see_reports',
    children: [
      {
        title: 'gaming_performance_report',
        route: 'apps-reports-gaming_performance',
        permission: 'see_gaming_performance_report',
      },
      //
      // {
      //   title: 'daily_reports',
      //   route: 'apps-reports-daily',
      //   permission: 'see_store_report',
      // },
      // {
      //   title: 'agent_reports',
      //   route: 'apps-reports-global',
      //   permission: 'see_global_report',
      // },
      {
        title: 'agent_reports_by_brands',
        route: 'apps-agents-by-brands-reports',
        permission: 'see_agents_by_brands_report',
      },
      {
        title: 'payment_methods_report',
        route: 'payment-methods',
        permission: 'see_payment_methods',
      },
      // {
      //   title: 'player_report',
      //   route: 'apps-reports-player',
      //   permission: 'see_player_report',
      // },
      {
        title: 'player_session_report',
        route: 'apps-reports-player_session',
        permission: 'see_player_session_report',
      },
      {
        title: 'player_brands_report',
        route: 'apps-reports-player-brands',
        permission: 'see_player_brands_report'
      },
      {
        title: 'recharge_retirement',
        route: 'apps-reports-rechargeRetirement',
        permission: 'see_recharges_and_withdrawals',
      },
      //
      {
        title: 'exclusions_report',
        route: 'apps-exclusions-report',
        permission: 'see_exclusions_report',
      },
      {
        title: 'big_prizes',
        route: 'apps-big-prizes-report',
        permission: 'see_big_prizes_report',
      },
      //
      // {
      //   title: 'report_products',
      //   icon: 'ArchiveIcon',
      //   permission: 'see_product_report',
      //   children: [
      //     {
      //       title: 'profit',
      //       route: 'apps-reports-profitProducts',
      //       permission: 'see_product_report',
      //     },
      //     {
      //       title: 'most_played',
      //       route: 'apps-reports-mostPlayed',
      //       permission: 'see_product_report',
      //     },
      //     {
      //       title: 'by_games',
      //       route: 'apps-reports-byGames',
      //       permission: 'see_product_report',
      //     }
      //   ],
      // },
      // {
      //   title: 'report_brands',
      //   icon: 'ArchiveIcon',
      //   permission: 'see_brands_report',
      //   children: [
      //     {
      //       title: 'profit',
      //       route: 'apps-reports-profitBrands',
      //       permission: 'see_brands_report',
      //     },
      //     {
      //       title: 'most_played',
      //       route: 'apps-reports-brands-mostPlayed',
      //       permission: 'see_brands_report',
      //     },
      //     {
      //       title: 'by_games',
      //       route: 'apps-reports-brands-byGames',
      //       permission: 'see_brands_report',
      //     }
      //   ],
      // },
      {
        title: 'report_horses',
        icon: 'ArchiveIcon',
        permission: 'see_horse_report',
        children: [
          {
            title: 'bets_placed',
            route: 'apps-reports-betsPlaced',
            permission: 'see_horse_report',
          },
        ],
      },
      {
        title: 'report_sports',
        icon: 'ArchiveIcon',
        permission: 'see_sports_report',
        children: [
          {
            title: 'bets_placed',
            route: 'apps-reports-sports',
            permission: 'see_sports_report',
          },
          // {
          //   title: 'bets_placed_tmp',
          //   route: 'apps-reports-sports-tmp',
          //   permission: 'see_sports_report',
          // },
          // {
          //   title: 'bets_placed_agency_bank',
          //   route: 'apps-reports-sports-ab',
          //   permission: 'see_sports_report',
          // },
        ],
      },
      {
        title: 'report_ticket_sports_live',
        icon: 'ArchiveIcon',
        permission: 'see_ticket_sports_live',
        children: [
          {
            title: 'bets_placed',
            route: 'apps-reports-ticket-sports-live',
            permission: 'see_ticket_sports_live',
          },
          {
            title: 'live_betting',
            route: 'apps-reports-liveBetting',
            permission: 'see_big_prizes_report',
          },
        ],
      },
      // {
      //   title: 'report_currencies',
      //   icon: 'ArchiveIcon',
      //   permission: 'see_currencies',
      //   children: [
      //     {
      //       title: 'accumulated',
      //       route: 'apps-currencies-accumulated',
      //       permission: 'see_currencies',
      //     },
      //     {
      //       title: 'products',
      //       route: 'apps-currencies-products',
      //       permission: 'see_currencies',
      //     },
      //   ],
      // },
    ],
  },
  {
    title: 'games',
    icon: 'CpuIcon',
    permission: 'see_games',
    route: 'games-list',
  },
  {
    title: 'audit',
    icon: 'FileIcon',
    route: 'modules-audit',
    permission: 'audit_list'
  },
  {
    title: 'report.module_tittle',
    icon: 'MessageSquareIcon',
    route: 'apps-messages-list',
    permission: 'messages-list'
  },
  {
    title: 'request_for_exclusions',
    icon: 'LockIcon',
    route: 'apps-exclusion-list',
    permission: 'exclusion-list'
  },
  {
    title: 'revocations',
    icon: 'RotateCcwIcon',
    route: 'apps-revocation-list',
    permission: 'see_revocation_list'
  },
  /*
  {
    title: 'Lobbies',
    icon: 'LayoutIcon',
    route: 'apps-lobbies-list',
    children: [
      {
        title: 'List',
      },
    ],
  },
*/
  // {
  //   title: 'Pages',
  //   icon: 'FileIcon',
  //   children: [
  //     {
  //       title: 'Authentication',
  //       icon: 'CircleIcon',
  //       children: [
  //         {
  //           title: 'Login v1',
  //           route: 'auth-login-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Login v2',
  //           route: 'auth-login-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Register v1',
  //           route: 'auth-register-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Register v2',
  //           route: 'auth-register-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Forgot Password v1',
  //           route: 'auth-forgot-password-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Forgot Password v2',
  //           route: 'auth-forgot-password-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Reset Password v1',
  //           route: 'auth-reset-password-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Reset Password v2',
  //           route: 'auth-reset-password-v2',
  //           target: '_blank',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Account Settings',
  //       route: 'pages-account-setting',
  //     },
  //     {
  //       title: 'Profile',
  //       route: 'pages-profile',
  //     },
  //     {
  //       title: 'Faq',
  //       route: 'pages-faq',
  //     },
  //     {
  //       title: 'Knowledge Base',
  //       route: 'pages-knowledge-base',
  //     },
  //     {
  //       title: 'Pricing',
  //       route: 'pages-pricing',
  //     },
  //     {
  //       title: 'Blog',
  //       children: [
  //         {
  //           title: 'List',
  //           route: 'pages-blog-list',
  //         },
  //         {
  //           title: 'Detail',
  //           route: { name: 'pages-blog-detail', params: { id: 1 } },
  //         },
  //         {
  //           title: 'Edit',
  //           route: { name: 'pages-blog-edit', params: { id: 1 } },
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Mail Templates',
  //       children: [
  //         {
  //           title: 'Welcome',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
  //         },
  //         {
  //           title: 'Reset Password',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-reset-password.html',
  //         },
  //         {
  //           title: 'Verify Email',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-verify-email.html',
  //         },
  //         {
  //           title: 'Deactivate Account',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-deactivate-account.html',
  //         },
  //         {
  //           title: 'Invoice',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-invoice.html',
  //         },
  //         {
  //           title: 'Promotional',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-promotional.html',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Miscellaneous',
  //       icon: 'CircleIcon',
  //       children: [
  //         {
  //           title: 'Coming Soon',
  //           route: 'misc-coming-soon',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Not Authorized',
  //           route: 'misc-not-authorized',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Under Maintenance',
  //           route: 'misc-under-maintenance',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Error',
  //           route: 'misc-error',
  //           target: '_blank',
  //         },
  //       ],
  //     },
  //   ],
  // },
]
